import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreFormat from 'GlobalStores/Format/StoreFormat'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'
import StoreProducts from 'GlobalStores/StoreProducts'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreUser from 'GlobalStores/User/StoreUser'
import { get } from 'lodash'
import { runInAction } from 'mobx'
import { FormatAPIs } from 'Services/FeelGreat/FormatAPIs'
import { GetFeelGreatProduct } from 'Services/FeelGreat/useFeelGreat'
import { Customers } from './Customers/Customers'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'

export async function Initialize() {
    runInAction(() => (StoreCheckout.checkoutPreparing = true))
    const qs = new URLSearchParams(window.location.search)

    if (qs.get('uuid')) {
        // ---> Get UUID
        // ---> Check ว่า data มาจาก login หรือ referral id
        // ---> ถ้า login เริ่มใหม่ --> Redirect กลับไปที่ / (root) เอา uuid
        // ---> ถ้า referral ---> Get customer data **
        // ---> Get Feelgreat with ba status
        // ---> Init data จาก Format API ใส่ MOBX

        if (StoreCountry.Country3() && StoreCheckout.CheckOutInstance()) {
            if (StoreCheckout.GetUuid().length === 0) {
                const settingResponse = await GetFeelGreatProduct()
                StoreLoader.InitAfterLoadSetting(settingResponse.data)
                await GetUuid(qs.get('uuid'), StoreCountry.Country3())
                await SetData()
            }
        }
    } else {
        if (StoreCountry.Country3() && StoreLoader.IsMainApiLoaded('settings') === false) {
            const [settingResponse, subscriptionConfigResponse] = await Promise.all([GetFeelGreatProduct(), FeelGreatAPI.GetSubscriptionConfig()])
            StoreLoader.InitAfterLoadSetting(settingResponse.data, subscriptionConfigResponse.data)

            runInAction(() => {
                // StoreCheckout.dataReady = true
                StoreLoader.SetApiLoaded('settings', true)
            })
        }
    }
}

export const SetData = async () => {
    if (StoreProducts.GetIsInit()) {
        if (StoreCheckout.GetSelectedItem() === '') {
            StoreCheckout.SetSelectedItem(StoreFeelGreat.defaultValues.flavor)
        }

        if (StoreCheckout.GetSelectedPurchaseOption() === '') {
            StoreCheckout.SetSelectedPurchaseOption(StoreFeelGreat.defaultValues.purchaseOption)
        }
    }

    const detail_page = StoreFormat.GetDetailPage()
    let formData = StoreFeelGreat.GetCheckoutForms()
    Object.keys(formData).map(key => {
        Object.keys(detail_page).map(detailPagekey => {
            formData[key].inputs.forEach(formKey => {
                if (detailPagekey === formKey.key) {
                    formKey.value = detail_page[detailPagekey]
                } else if (detailPagekey.toLowerCase() === formKey.key.toLowerCase()) {
                    formKey.value = detail_page[detailPagekey]
                } else if (detailPagekey === 'zip' && formKey.key === 'zip_code') {
                    formKey.value = detail_page[detailPagekey]
                } else if (detailPagekey === 'address' && formKey.key === 'street_address') {
                    formKey.value = detail_page[detailPagekey]
                }
            })
            return true
        })
        return true
    })

    StoreFeelGreat.Init(formData)
    StoreCheckout.Init(formData)

    if (get(detail_page, 'ship_address_options', false)) {
        runInAction(() => (StoreCheckout.useBillingAddressSameAsShipping = false))
        Object.keys(detail_page).map(key => {
            if (/^ship_address+?/.test(key)) {
                let newKey = key.replace('ship_address_', '')
                StoreCheckout.SetBillingAddressForm(newKey, detail_page[key])
            }
        })
    }

    await StoreCheckout.RestoreCart(JSON.parse(detail_page.shipment_packs))
    if (/payment-response/.test(window.location.href)) {
        runInAction(() => {
            StoreLoader.SetApiLoaded('settings', true)
        })
    }
}

export const GetCustomer = async (customerId = null) => {
    const response = await Customers.GetCustomer(customerId)
    const item = get(response.data, 'items[0]')

    if (item) {
        let baStatus = StoreUser.CustomerType(item.type, item.status).short || null
        await StoreReferral.SetReferralData(item, baStatus)
        const settingResponse = await GetFeelGreatProduct(baStatus)
        StoreLoader.InitAfterLoadSetting(settingResponse.data)
        StoreCheckout.SetUseReferralPrice(true)
    }
}

export const GetUuid = async (uuid, countryCode3Alpha) => {
    try {
        if (uuid && countryCode3Alpha) {
            const response = await FormatAPIs.getHotFormatV2(uuid, countryCode3Alpha)
            const loginId = get(response.data, 'ushop.detail_page.login_id')
            const referralId = get(response.data, 'ushop.detail_page.referral_id')

            if (loginId) {
                const token = get(response.data, 'ushop.detail_page.token')
                // const userResponse = await userAPI.getMenuWithLogin(loginId, countryCode3Alpha, token)
                // StoreAuth.initUser(userResponse.data, token)
                // await GetFeelGreatProduct(response.data.userStatus)
                await StoreAuth.getCustomerMenu(loginId, countryCode3Alpha, token)
            } else {
                if (referralId) {
                    await GetCustomer(referralId)
                } else {
                    const settingResponse = await GetFeelGreatProduct()
                    StoreLoader.InitAfterLoadSetting(settingResponse.data)
                }
            }
            StoreCheckout.uuid = uuid
            await StoreFormat.SetData(response.data)
        }
    } catch (e) {}
}
