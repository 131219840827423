import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { get, isObject } from 'lodash'
import userAPI from './APIs'
import StoreCountry from '../Cores/County/StoreCountry'
import StoreUser from './StoreUser'
import { ServerEnv } from 'Configs/ServerEnv'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeCredit from 'GlobalStores/StoreCredit'
import httpHelper from 'Services/HttpHelper'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import SessionAuth from './SessionAuth'

let allowStatus = [200, 201]

class StoreAuth {
    id = null
    token = null
    tokenExpire = null
    href = null
    whoami = null
    acl = null
    country = ''
    allowCredit = null
    userRank = null
    userStatus = null
    userCountry = null
    isLogin = false
    loadingLogin = false
    loadingUserMenu = false

    constructor() {
        makeAutoObservable(this)

        reaction(
            () => StoreCountry.Country3(),
            (country, prev, disposer) => {
                if (country) {
                    this.country = country
                    disposer.dispose()
                }
            }
        )
    }

    async Login({ unicityID, password }) {
        let response = await userAPI.login(unicityID, password)
        if (allowStatus.includes(response.status)) {
            if (['JPN'].includes(StoreCountry.Country3())) {
                if (response.data.userStatus !== 'H' && response.data.userStatus !== 'M') {
                    const error = new Error()
                    error.message = 'user_status_not_allowed'
                    throw error
                }
            }
            await this.IsAllowUser(response.data.baId)
            await this.initUser(response.data)
            // const settingResponse = await GetFeelGreatProduct(response.data.userStatus)
            // StoreLoader.InitAfterLoadSetting(settingResponse.data)
            const profileResponse = await this.getCustomerMenu(this.id, this.country, this.GetToken())
            StoreUser.setUserData(profileResponse.onself.ushop)

            return response.data
        } else {
            return null
        }
    }

    async IsAllowUser(baID) {
        try {
            await FeelGreatAPI.GetGeoIPAccessAndBAID({ baID })
        } catch (error) {
            console.log('You do not have permission ', error)
            window.location.replace(`https://www.unicity.com`)
        }
    }

    async changePassword({ currentPassword, newPassword }) {
        let response = [null, null]
        /**
         * Current password checking
         */
        try {
            await userAPI.login(this.id, currentPassword)
        } catch (error) {
            if (error.response) {
                response[1] = 'please_recheck_your_confirm_password'
                return response
            }
        }

        /**
         * New password calling
         */
        try {
            await userAPI.changePassword(this.token, newPassword)
            response[0] = 'Change password is successes.'
        } catch (error) {
            if (error.response) {
                response[1] = 'Some problem with change password'
                return response
            }
        }
        await this.Login({ unicityID: this.id, password: newPassword }) // Login after password has been changed.
        return response
    }

    async updateUserDetails({ emailAddress, mobilePhone }) {
        let response = [null, null]
        try {
            await userAPI.updateUserDetails(this.token, { emailAddress, mobilePhone })
            StoreUser.updateUserData({ emailAddress, mobilePhone })
            response[0] = 'Updated user success.'
        } catch (error) {
            if (error.response) {
                response[1] = 'Update user details failed!'
            }
        }
        return response
    }

    async updateUserProfilePicture(media) {
        let response = [null, null]

        try {
            let res = await userAPI.updateUserProfilePicture(this.token, { baId: this.id, media })
            response[0] = res.data
        } catch (error) {
            console.log('error UploadImage', media)
            if (error.response) {
                response[1] = 'Update profile picture failed!'
            }
        }
        return response
    }

    async removeUserProfilePicture() {
        const response = [null, null]

        try {
            const res = await userAPI.removeUserProfilePicture(this.token)
            response[0] = res
        } catch (error) {

            console.log('error Remove Profile Picture', error)

            if (error.response) {
                response[1] = 'Remove profile picture failed!'
            }
        }
        return response
    }

    async getCustomerSite() {
        
        let result = {
            isSuccess: false,
            data: null
        }
        try {

            const response = await userAPI.getCustomerSite(this.token)

            if (response.status === 200) {
                result = {
                    data: response.data,
                    isSuccess: true
                }
            }

        } catch (error) {
            console.log('error getCustomerSite', error)

            if (error.response.status === 404) {
                result = {
                    isSuccess: true,
                    data: null
                }
            }
        }

        return result
    }

    async updateDisplayName({ firstName, lastName, alias }) {

        let result = {
            isSuccess: false,
            firstName: '',
            lastName: ''
        }

        const updateData = {
            content: {
                humanName: {
                    firstName: firstName,
                    lastName: lastName
                }
            },
            alias: alias
        }

        try {
            const response = await userAPI.updateCustomerSite(this.token, updateData)
            if (response.status === 200) {
                result = {
                    isSuccess: true,
                    firstName: firstName,
                    lastName: lastName
                }

            } else {
                throw new Error('Update preferred name API failed')
            }

        } catch (error) {
            console.log("error updatePreferredName ", error)
            result = {
                ...result,
                isSuccess: false
            }
        }

        return result
    }

    async initUser(response, token) {
        try {
            if (response && response.onself === undefined) {
                const userInit = {
                    id: this.id,
                    token: this.token,
                    menu: this.menu,
                    href: this.href,
                    country: StoreCountry.Country3().toLowerCase()
                }

                userInit.id = response.baId
                userInit.href = response.href

                Object.keys(sessionStorage).map((v, k) => {
                    if (/^menuGroup+?/.test(v)) {
                        sessionStorage.removeItem(v)
                    }
                    return ''
                })
                Object.keys(localStorage)
                    .filter(item => item !== 'currentCountry')
                    .map((v, k) => {
                        if (/^dashboard+?/.test(v)) {
                            localStorage.removeItem(v)
                        }
                        return ''
                    })

                const loginData = get(response, 'loginData', null)
                if (loginData) {
                    userInit.token = loginData.token
                } else {
                    if (response.checkToken) {
                        userInit.token = response.checkToken.token
                    }
                }

                this.id = userInit.id
                this.token = userInit.token
                this.userStatus = response.userStatus
                this.allowCredit = response.allowCredit

                // if (this.allowCredit.enable) {
                //     await storeCredit.Init({ id: userInit?.id, token: userInit?.token })
                // }
                Object.keys(response.allowCredit).map((v, k) => {
                    if (v === 'enable') {
                        this.allowCredit = response.allowCredit
                        this.allowCredit.enable = true
                    }
                    return ''
                })

                runInAction(() => {
                    this.isAuthorized = true
                    this.isInitilized = true
                })

                this.saveUser(userInit)

                return response
            } else {
                if (response && response.onself) {
                    this.id = response.baId
                    this.userStatus = response.userStatus
                    this.allowCredit = response.allowCredit

                    Object.keys(response.allowCredit).map((v, k) => {
                        if (v === 'enable') {
                            this.allowCredit = response.allowCredit
                            this.allowCredit.enable = true
                        }
                        return ''
                    })

                    runInAction(() => {
                        this.isAuthorized = true
                        this.isInitilized = true
                    })
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    async getCustomerMenu(baID = this.id, country = this.country, token = this.GetToken()) {
        this.loadingUserMenu = true
        let response = await userAPI.getMenuWithLogin(baID, country, token)

        if (allowStatus.includes(response.status)) {
            let userData = response.data

            this.initUser(userData)
            StoreUser.setUserData(userData.onself.ushop)
            this.href = userData.onself.ushop.profile.href
            this.token = token
            this.isLogin = true
            this.loadingUserMenu = false
            this.userCountry = userData.userCountry
            return response.data
        } else {
            this.loadingUserMenu = false
            return null
        }
    }

    saveUser = session => {
        // console.log("userInit ", session);
        // StoreUser.setUserData(response.onself.ushop);
        // StoreUser.setUserData(response.onself.ushop);
        SessionAuth.SaveSession(session)
    }

    resetPassword = async input => {
        try {
            const re = /\S+@\S+\.\S+/
            const validEmail = re.test(input.emailOrId)
            const dataDistributorID = {
                preferredDelivery: input.type,
                customer: { href: `${ServerEnv.Hydra()}/customers?id.unicity=${input.emailOrId}` }
            }

            const dataEmail = {
                preferredDelivery: input.type,
                email: input.emailOrId
            }

            const dataAll = {
                preferredDelivery: input.type,
                email: input.emailOrId,
                customer: { href: `${ServerEnv.Hydra()}/customers?id.unicity=${input.customerId}` }
            }

            if (input.customerId) {
                let response = await userAPI.resetPassword(dataAll, {
                    language: (StoreTranslate.CurrentLanguage() || '').toLowerCase(),
                    country: StoreCountry.Country2()
                })
                return response.data
            }
            let response = await userAPI.resetPassword(validEmail ? dataEmail : dataDistributorID, {
                language: (StoreTranslate.CurrentLanguage() || '').toLowerCase(),
                country: StoreCountry.Country2()
            })
            return response.data
        } catch (error) {
            return error.response.data.error
        }
    }

    setNewPassword = async input => {
        try {
            let response = await userAPI.newPassword(input)
            return response.data
        } catch (error) {
            return error.response.data.error
        }
    }

    GetId() {
        return this.id
    }

    IsAuthorized() {
        return this.isLogin || this.isAuthorized || this.GetToken()
    }

    GetToken() {
        return this.token
    }

    async AutoLogin(token) {
        try {
            const customer = await this.GetCustomerByBrokerToken(token)
            const response = await this.getCustomerMenu(customer.payload.baId, customer.payload.country, customer.payload.token)
            if (this.allowCredit) {
                storeCredit.Init({ id: this.id, token: this.token })
            }
            return response.data
        } catch (error) {}
    }

    async GetCustomerByBrokerToken(token) {
        try {
            const response = await httpHelper.Get({ url: `https://member-calls2.unicity.com/hot/remoteStorage/broker/${token}` })
            return response.data
        } catch (error) {
            return error.response.data.estorerror
        }
    }

    async RestoreSession(customer) {
        const response = await this.getCustomerMenu(customer.id, customer.country, customer.token)
        if (this.allowCredit) {
            storeCredit.Init({ id: this.id, token: this.token })
        }
        return response.data
    }

    SetCredit(responseCredit) {
        Object.keys(responseCredit).map((v, k) => {
            if (v.enable === 'enable') {
                this.allowCredit = responseCredit[v]
                this.allowCredit.enable = true
            }
            return ''
        })
    }

    IsAllowCredit() {
        let isReturn = false
        if (isObject(this.allowCredit)) {
            Object.keys(this.allowCredit).map(v => {
                if (this.allowCredit[v].enable) {
                    isReturn = true
                }
            })
        }

        return isReturn
    }

    logout() {
        StoreUser.reset()
        this.reset()
        window.location = '/'
    }

    GetUserCountry() {
        return this.userCountry
    }

    async removeUserProfilePicture() {
        const response = [null, null]

        try {
            const res = await userAPI.removeUserProfilePicture(this.token)
            response[0] = res
        } catch (error) {
            console.log('error Remove Profile Picture', error)

            if (error.response) {
                response[1] = 'Remove profile picture failed!'
            }
        }
        return response
    }

    async getCustomerSite() {
        let result = {
            isSuccess: false,
            data: null
        }
        try {
            const response = await userAPI.getCustomerSite(this.token)

            if (response.status === 200) {
                result = {
                    data: response.data,
                    isSuccess: true
                }
            }
        } catch (error) {
            console.log('error getCustomerSite', error)

            if (error.response.status === 404) {
                result = {
                    isSuccess: true,
                    data: null
                }
            }
        }

        return result
    }

    async updateDisplayName({ firstName, lastName, alias }) {
        let result = {
            isSuccess: false,
            firstName: '',
            lastName: ''
        }

        const updateData = {
            content: {
                humanName: {
                    firstName: firstName,
                    lastName: lastName
                }
            },
            alias: alias
        }

        try {
            const response = await userAPI.updateCustomerSite(this.token, updateData)
            if (response.status === 200) {
                result = {
                    isSuccess: true,
                    firstName: firstName,
                    lastName: lastName
                }
            } else {
                throw new Error('Update preferred name API failed')
            }
        } catch (error) {
            console.log('error updatePreferredName ', error)
            result = {
                ...result,
                isSuccess: false
            }
        }

        return result
    }

    reset() {
        this.id = null
        this.token = null
        this.tokenExpire = null
        this.href = null
        this.isAuthorized = false
        this.isDummyUser = false
        this.whoami = null
        this.acl = null
        this.country = null
        this.userStatus = null
    }
}
const storeAuth = new StoreAuth()
export default storeAuth
