import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { camelCase, get, isEmpty, isObject } from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import TrackingEvents from 'Services/Tracker/TrackingEvents'
import Payment from '../Payment'
import { PaymentAPIs } from '../PaymentServices/PaymentAPI'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import storeCredit from 'GlobalStores/StoreCredit'
import States from 'Components/Elements/Forms/StatesModal/Country/AustraliaStates.json'
import storeFormAddress from 'GlobalStores/Checkout/StoreFormAddress'
import { FormatAPIs } from 'Services/FeelGreat/FormatAPIs'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import storeUser from 'GlobalStores/User/StoreUser'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeTaxInvoice from '../StoreTaxInvoice'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'

class Thailand {
    autoAddressDisplayKeys = ['street', 'locality', 'postal_code']

    autoAddressKeyToForm = {
        sub1: 'district',
        sub2: 'sub_district',
        city: 'province',
        zip: 'zip_code'
    }

    chainAddress = {
        isChainAddress: false,
        groupAddress: []
    }

    uuid = ''
    isAcceptPdpa = false
    selectShippingMethod = ''
    shippingMethod = 'delivery'
    paymentMethod = ''
    paymentErrors = []

    toFormat = {
        enroll: {
            uuid: this.GetUuid(),
            fullName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            password: ''
        },
        enrollWithReferral: {
            uuid: this.GetUuid(),
            fullName: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            password1: '',
            password2: ''
        },
        shop: {
            uuid: this.GetUuid(),
            fullname: '',
            fullname_en: '',
            email: '',
            phone: '',
            password: '',
            zip: '',
            province: '',
            district: '',
            sub_district: '',
            house_number: ''
        }
    }

    formShop = {
        fullName: '',
        email: '',
        phone: '',
        street_address: '',
        city: '',
        state: '',
        zip_code: ''
    }

    createAccountWithShippingAddress = {
        type: 'enroll',
        referral_id: '',
        fullname: '',
        fullname_en: '',
        email: '',
        phone: '',
        password: '',
        zip: '',
        province: '',
        district: '',
        sub_district: '',
        house_number: '',
        period: ''
    }

    ExcludedFieldsShippingIfCreateAccount = []

    ExcludedFieldsShipping = ['fullName', 'country']

    mapFormKeyToFormatKey = {
        fullNameNative: 'fullname',
        fullName: 'fullname_en',
        zip_code: 'zip',
        email: 'email',
        phone: 'phone',
        password: 'password',
        house_number: 'house_number',
        street: 'street',
        sub_district: 'sub_district',
        district: 'district',
        province: 'province',
        zip_code: 'zip'
    }

    ArrangeCreateCheckoutForm = [
        'fullNameNative',
        'fullName',
        'email',
        'phone',
        'password',
        'zip_code',
        'province',
        'district',
        'sub_district',
        'street',
        'house_number'
    ]

    PaymentStep = {
        prepare_data: 0,
        payment: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    breadcrumbOption = [
        {
            caption: 'customise',
            link: '/products',
            paramsRequire: false
        },
        {
            caption: 'signup_shipping',
            link: '/checkout/create-account',
            paramsRequire: true,
            authCaption: 'shipping',
            authLink: '/checkout/shipping'
        },
        {
            caption: 'billing',
            link: '/checkout/payment',
            paramsRequire: true
        },
        {
            caption: 'purchase',
            link: '/checkout/review',
            paramsRequire: true
        }
    ]

    shippingMethods = [
        {
            id: 1,
            api_value: 'delivery',
            title: 'Delivery',
            dict_key: 'delivery',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    value: 'creditcard',
                    dict_key: 'ref_creditcard',
                    for_status: [],
                    icon: 'creditcard-icon'
                },
                {
                    id: 2,
                    title: 'QR payment',
                    value: 'qr_payment',
                    dict_key: 'qr_payment',
                    descripion: {
                        title: '',
                        dict_key: 'qr_payment'
                    },
                    for_status: [],
                    icon: 'pp-icon'
                }
            ]
        }
    ]

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: true,
            dict_key: 'shipping'
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: true,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        }
    }

    landingLayouts = [
        {
            nameComponent: 'banner',
            titleContent: 'mainBanner',
            style: {}
        },
        {
            nameComponent: 'ourApproach',
            titleContent: 'ourApproach',
            style: {
                minHeight: '0px'
            }
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {
                backgroundColor: '#F1F6FB'
            }
        },
        {
            nameComponent: 'thePractice',
            titleContent: 'thePractice',
            style: {}
        },
        {
            nameComponent: 'howItWorks',
            titleContent: 'howItWorks',
            style: {
                backgroundColor: '#FBF7F1'
            }
        },
        {
            nameComponent: 'whatExpertsSay',
            titleContent: 'expertsSay',
            style: {}
        },
        {
            nameComponent: 'realResults',
            titleContent: 'realResults',
            style: {
                backgroundColor: '#F2F9F8'
            }
        }
    ]

    productLayouts = [
        {
            nameComponent: 'productDetailSelection',
            titleContent: 'productDetailSelection',
            style: {}
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {}
        },
        {
            nameComponent: 'frequentlyAskedQuestions',
            titleContent: 'questions',
            style: {
                backgroundColor: '#F6F9FC'
            }
        }
    ]

    provinceList = []

    GetPaymentErrors() {
        return this.paymentErrors
    }

    StatesList() {
        let items = []
        States.map(raw => {
            items.push({
                text: raw.abbreviation,
                value: raw.abbreviation,
                html: `<span>${raw.name}</span>`
            })
        })
        return items
    }

    AddressOptionList(key) {
        let items = []
        States.map(raw => {
            items.push({
                text: raw,
                value: raw,
                html: `<span>${raw}</span>`
            })
        })
        return items
    }

    MapAutoAddressToForm(form, autoAddressData) {
        const swappedValuesAsKeys = ObjectHelpers.swapKeysAndValues(this.autoAddressKeyToForm)

        const swappedValuesAsKeysWithAssignKey = Object.fromEntries(
            Object.entries(swappedValuesAsKeys).map(([key, value]) => (key === 'zip_code' ? [key, value] : [key, ObjectHelpers.TowRomanAndNativeKey(value)]))
        )

        return form.map(item => {
            if (swappedValuesAsKeys[item.key]) {
                if (autoAddressData.data) {
                    item.value = autoAddressData.data[swappedValuesAsKeys[item.key]]
                }

                if (autoAddressData) {
                    item.value = autoAddressData[swappedValuesAsKeysWithAssignKey[item.key]]
                }
            }

            return item
        })
    }

    FormToFormat(type, form) {
        form.map(item => {
            if (this.mapFormKeyToFormatKey[item.key]) {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][this.mapFormKeyToFormatKey[item.key]] = item.value
                    })
                }
            } else {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][item.key] = item.value
                    })
                }
            }

            return item
        })
    }

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetFormat(type) {
        return this.toFormat[type]
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetBreadcrumbOption() {
        return this.breadcrumbOption
    }

    GetShippingMethod() {
        return this.shippingMethods.find(item => item.api_value === this.shippingMethod)
    }

    GetShippingMethods() {
        return this.shippingMethods
    }

    SetPaymentMethod(value) {
        runInAction(() => {
            this.paymentMethod = value
        })
    }

    GetPaymentMethod() {
        return this.paymentMethod
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    GetLandingLayout() {
        return this.landingLayouts
    }

    GetProductLayout() {
        return this.productLayouts
    }

    GetIsAcceptPdpa() {
        return this.isAcceptPdpa
    }

    SetIsAcceptPdpa(value) {
        this.isAcceptPdpa = value
    }

    async getPaymentData({ type, CreditCardEncrypted, Pay, overridedTotalPrice = 0 }) {
        const totalPrice = get(storeFormat, 'data.ushop.u23.formatSummary.order_total', overridedTotalPrice)
        if (totalPrice === 0) return []

        let data = []

        const allowCreditEnable = get(StoreAuth, 'allowCredit.enable', false)

        if (type === 'qr') {
            const qrPayment = {
                method: 'qrPayment',
                amount: Number(totalPrice)
            }
            data.push(qrPayment)

            if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                const productCredit = storeCredit.GetCreditData()
                const useCredit = { method: 'eCredit', amount: productCredit.creditUsed }
                data.push(useCredit)
            }
        } else {
            // Prepare credit card data
            let creditCardData = {
                amount: Number(totalPrice)
            }

            if (!isEmpty(CreditCardEncrypted)) {
                creditCardData['method'] = camelCase(CreditCardEncrypted.method)
                creditCardData['payer'] = CreditCardEncrypted.methodDetails.payer
                creditCardData['creditCardNumber'] = CreditCardEncrypted.methodDetails.creditCardNumber
                creditCardData['creditCardSecurityCode'] = CreditCardEncrypted.methodDetails.creditCardSecurityCode
                creditCardData['creditCardExpires'] = CreditCardEncrypted.methodDetails.creditCardExpires
            }

            data.push(creditCardData)
            // END Prepare credit card data

            // When customer have E-Credit

            if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                const productCredit = storeCredit.GetCreditData()
                const useCredit = { method: 'eCredit', amount: productCredit.creditUsed }
                data.push(useCredit)
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted, useCredit]
                }
            } else {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted]
                }
            }
        }

        return data
    }

    /**
     *
     * @param {*} uuid
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    async SubmitPayment({ uuid, detailPage, CreditCardEncrypted, products, type = '' }) {
        try {
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //4111 1111 1111 1111
            runInAction(() => {
                StorePaymentStep.SetPaymentStep('prepare_data', 0)
            })
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            const Pay = new Payment(uuid, detailPage)
            storeCheckout.uuid = uuid

            Pay.hydra = {
                transaction: {}
            }

            const payments = await this.getPaymentData({
                type,
                CreditCardEncrypted,
                Pay,
                overridedTotalPrice: get(storeFormat, 'data.hydra.marketing.terms.total', 0)
            })

            const customerType = get(storeUser.CustomerData(), 'userData.type', '')

            //////////////////////////////////////////////////////////////
            if (
                storeAuth.IsAuthorized() &&
                !get(storeUser.CustomerData(), 'userData.taxTerms.taxId', '').length &&
                storeTaxInvoice.requestTaxInvoice &&
                customerType === 'Member'
            ) {
                // New
                const formatTaxInvoice = get(storeFormat, 'data.ushop.u23.formatTaxInvoice', {})

                PaymentAPIs.CustomerInformationUpdate({
                    token: storeAuth.GetToken(),
                    customer_href: storeUser.CustomerData().userData.href,
                    customer_information: formatTaxInvoice
                })
            }
            //////////////////////////////////////////////////////////////

            const allowCreditEnable = StoreAuth.IsAllowCredit()

            if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted, storeCredit.ProductCreditUsed()]
                }
            } else {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted]
                }
            }
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            runInAction(() => {
                StorePaymentStep.SetPaymentStep('prepare_data', 1)
            })
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            let oneTime = await Pay.SubmitOneTimePayment({ payments, type }) // Main
            if (!oneTime) {
                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                runInAction(() => {
                    // StorePaymentStep.SetPaymentStep('createOrder', -1)
                    StorePaymentStep.SetPaymentStep('payment', -1)
                })
                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                return { success: false }
            }

            await Pay.PreparePostData(oneTime)

            const formatTaxInvoiceHydra = get(storeFormat, 'data.ushop.u23.formatTaxInvoiceHydra', {})
            const formDataHydra = get(oneTime, 'formatData.hydra', {})

            let getCreateCustomerForm = formDataHydra.customer
            if (!StoreAuth.isAuthorized && storeTaxInvoice.requestTaxInvoice) {
                delete getCreateCustomerForm['humanName']
                delete getCreateCustomerForm['mainAddress']

                getCreateCustomerForm = {
                    ...getCreateCustomerForm,
                    ...formatTaxInvoiceHydra,
                    shipToName: formDataHydra.shipToName,
                    shipToAddress: formDataHydra.shipToAddress
                }
            }

            await storeUser.SetCustomerHydraPayload(getCreateCustomerForm)
            runInAction(() => {
                StorePaymentStep.SetPaymentStep('createOrder', 0)
            })
            if (type !== 'qr') {
                const readyToCreateData = {
                    referenceNo: oneTime.referenceNo,
                    status: 'waiting',
                    type: 'feelGreat',
                    token: `Bearer ${StoreAuth.GetToken()}`,
                    ba_id: StoreAuth.GetId()
                }

                await PaymentAPIs.UpdateReadyToCreateOrder(readyToCreateData)

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                runInAction(() => {
                    StorePaymentStep.SetPaymentStep('createOrder', 1)
                    StorePaymentStep.SetPaymentStep('payment', 1)
                })
                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            }

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            if (Pay.autoship && type !== 'qr') {
                runInAction(() => {
                    StorePaymentStep.SetPaymentStep('autoship', 0)
                })
                const createAutoshipData = await Pay.AutoshipTHA() // Just Autoship don't need to order to hydra again

                runInAction(() => {
                    StorePaymentStep.SetPaymentStep('autoship', 1)
                })
                // const patchUUID2 = {
                //     token: StoreAuth.GetToken(),
                //     send_email: 'feelGreat',
                //     autoship_number: Pay.autoshipNumber,
                //     uuid: uuid,
                //     selectAddressHistory: storeFormAddress.typeFormAddress,
                //     products_email: get(Pay.email, 'products_email', '')
                // }

                // await PaymentAPIs.UpdateUUid(patchUUID2, storeCountry.Country3(), true)

                let shipToName = ''
                if (isObject(formDataHydra.shipToName)) {
                    shipToName = formDataHydra.shipToName.firstName + ' ' + formDataHydra.shipToName.lastName
                } else {
                    shipToName = formDataHydra.shipToName
                }

                let shipToAddress = ''
                if (isObject(formDataHydra.shipToAddress)) {
                    shipToAddress =
                        formDataHydra.shipToAddress.address1 +
                        ' ' +
                        formDataHydra.shipToAddress.address2 +
                        ' ' +
                        formDataHydra.shipToAddress.city +
                        ' ' +
                        formDataHydra.shipToAddress.zip
                } else {
                    shipToAddress = formDataHydra.shipToAddress
                }

                FeelGreatAPI.SendEmailV4(
                    {
                        to_email: storeUser.CustomerData().Email(),
                        type: 'shop',
                        bcc: 'unicity.dev2018@gmail.com',
                        member_id: storeAuth.GetId(),
                        ship_to_name: shipToName,
                        ship_to_address: shipToAddress,
                        product_items: get(Pay.email, 'products_email', ''),
                        shipping_type: 'delivery',
                        autoship: {
                            autoship_number: Pay.autoshipNumber
                        },
                        total_price: get(storeFormat, 'data.hydra.marketing.terms.total', 0)
                    },
                    storeCountry.Country3()
                )
                Pay.SendSms()

                // console.log("Debug before call update log", toJS(createAutoshipData))

                await Pay.UpdateAutoshipLogPaymentTHA(createAutoshipData)

                runInAction(() => {
                    StorePaymentStep.SetPaymentStep('updateLog', 1)
                })

                runInAction(() => {
                    setTimeout(() => {
                        StorePaymentStep.PaymentDone = true
                    }, 1500)
                })
            }

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //TODO: Rechect to send autoship only
            // if (type !== 'qr') {
            //     runInAction(() => {
            //         StoreLoader.orderApiProgress.sendEmail = 1
            //     })
            // await Pay.SendEmailTHA()
            // }
            // runInAction(() => {
            //     StoreLoader.orderApiProgress.sendEmail = 2
            // })
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            let responseData = {
                success: true,
                payment_id: oneTime?.gateway_ref_id,
                order_number: oneTime.payment_id,
                payment_ref: oneTime?.paymentRef
            }

            if (detailPage.type === 'enroll') {
                responseData = {
                    ...responseData,
                    order_type: detailPage.type
                }
            }

            try {
                TrackingEvents.PurchaseEvent({
                    transaction_id: responseData.payment_id.payment_id,
                    value: Pay.ushop.total_price,
                    currency: 'THB',
                    category: Pay.ushop.shipment_purchas_option,
                    items: products
                })
            } catch (e) {
                console.error('TrackingEvents.PurchaseEvent', e)
            }

            return responseData

            // try {
            //     TrackingEvents.PurchaseEvent({
            //         transaction_id: responsePreparePayment.data.data.payment_id,
            //         value: Pay.ushop.total_price,
            //         currency: 'THB',
            //         category: Pay.ushop.shipment_purchas_option,
            //         items: products
            //     })
            // } catch (e) {
            //     console.error('TrackingEvents.PurchaseEvent', e)
            // }
            // return {
            //     success: true,
            //     payment_id: responsePreparePayment.data.data.payment_id,
            //     order_number: responsePaymentDetail.data.order_number
            // }
        } catch (error) {
            console.error(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })
            return { success: false }
        }
    }

    async CreateAccount(uuid, shippingForm, referral) {
        try {
            const forms = Object.assign(this.createAccountWithShippingAddress)
            const returnShippingAddress = {}

            shippingForm.map(item => {
                if (this.ExcludedFieldsShippingIfCreateAccount.includes(item.key)) {
                    return false
                }
                if (this.mapFormKeyToFormatKey[item.key]) {
                    forms[this.mapFormKeyToFormatKey[item.key]] = item.value
                    returnShippingAddress[this.mapFormKeyToFormatKey[item.key]] = item.value
                }
            })

            if (referral) {
                forms.referral_id = referral
            } else {
                delete forms.referral_id
            }

            delete forms.country

            forms.shipment_packs = JSON.stringify(storeCheckout.cart)
            forms.uuid = uuid

            if (!storeCheckout.period) {
                const periodResponse = await FeelGreatAPI.GetPeriod('TH')
                forms.period = periodResponse.data.entryPeriod
                storeCheckout.period = periodResponse.data.entryPeriod
            } else {
                forms.period = storeCheckout.period
            }

            //Only Thailand
            forms['is_new_ba'] = true

            const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/THA/feelGreat/createAccount?createAccountBody=1${
                ServerEnv.ENV === 'DEV' ? '&dev=1' : ''
            }`
            const response = await axios.post(url, forms)

            const result = {}

            if (response?.data?.success === false) {
                result.success = false
                result.data = response.data
            } else {
                result.success = true
                result.data = response.data
            }

            return result
        } catch (e) {
            console.error(e)
            let error = get(e, 'response.data.error.body.error.message', null)

            return {
                success: false,
                data: {
                    axiosError: error
                }
            }
        }
    }

    async FetchCityList() {
        try {
            const data = await FeelGreatAPI.GetCityList()
            runInAction(() => {
                this.provinceList = data.data.data
            })
        } catch (error) {
            console.error(error)
        }
    }

    async ContinueToPayment() {
        const response = {
            success: false
        }
        try {
            if (StoreAuth.IsAuthorized()) {
                const formatType = 'shop'
                storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetShippingForm())

                const data = storeCheckout.InitializeFormShippingData(formatType)

                // console.log('THA data', data)

                data.uuid = storeCheckout.GetUuid()
                delete data.period
                data.type = 'shop'

                if (storeCheckout.shippingMethod) {
                    data.shipment_options = storeCheckout.shippingMethod.api_value
                }

                if (StoreAuth.IsAuthorized()) {
                    data['ba_status'] = StoreAuth.userStatus
                    data['login_id'] = StoreAuth.GetId().toString()
                    data['login_name'] = storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'fullName')
                    data['login_name_native'] = storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'nativeName')
                    data['token'] = StoreAuth.GetToken()

                    delete data?.country

                    if (data.confirm_password) {
                        delete data.confirm_password
                    }
                }

                data.shipment_packs = JSON.stringify(storeCheckout.cart)
                data['tax_invoice'] = storeTaxInvoice.requestTaxInvoice ? 1 : 0

                if (storeFormAddress.typeFormAddress === 'addressHistory') {
                    data['selectAddressHistory'] = 'addressHistory'
                    data['fullName'] = get(storeFormAddress.GetDataMainAddress(), 'ushopShipToName', '')
                    data['shipToAddress'] = get(storeFormAddress.GetDataMainAddress(), 'shipToAddress', null)
                }

                if (storeFormAddress.typeFormAddress === 'new') {
                    data['shipToAddress'] = storeFormAddress.FormatShipToAddress(data)
                }

                const updateResponse = await FormatAPIs.putHotFormatV2(data, StoreCountry.Country3())

                if (updateResponse.data.success === false) {
                    response.success = false
                    runInAction(() => {
                        storeCheckout.checkoutErrors = updateResponse.data.Message_v2
                    })
                } else {
                    if (storeFormAddress.typeFormAddress === 'new') {
                        await storeFormAddress.SaveAddressHistory(data)

                        const addressHistoryResponse = await FeelGreatAPI.GetAddressHistory()

                        const { data: addressHistoryData } = addressHistoryResponse

                        if (addressHistoryData) {
                            if (addressHistoryData?.items?.length > 0) {
                                storeFormAddress.displayMainAddress = addressHistoryData.items[0]
                                storeFormAddress.SetAddressHistorySelected(addressHistoryData.items[0])

                                addressHistoryData.items?.push(addressHistoryData.mainAddress)

                                const filterItemsByCountry = addressHistoryData.items?.filter(item => item.shipToAddress?.country === storeCountry.Country2())

                                runInAction(() => {
                                    if (filterItemsByCountry.length === 0) {
                                        storeFormAddress.typeFormAddress = 'new'
                                    } else {
                                        storeFormAddress.listAddress = filterItemsByCountry
                                    }
                                })
                            }
                        }
                    }

                    storeCheckout.checkoutErrors = []
                    response.success = true
                }
            }
        } catch (e) {
            console.log('Thailand ContinueToPayment error', e)
            if (StoreAuth.IsAuthorized()) {
                if (!StoreAuth.GetId()) {
                    window.location.reload()
                }
            } else {
                response.success = false
                runInAction(() => (storeCheckout.dataReady = true))
                console.log(e)
            }
        }
        return response
    }

    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        this.FetchCityList()
        makeAutoObservable(this)
    }
}

export default Thailand
