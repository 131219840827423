import styled from '@emotion/styled'
import { Box, ThemeProvider, Typography } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { observer } from 'mobx-react-lite'

const BoxSelectionItem = observer(props => {
    return (
        <ThemeProvider theme={MainTemplate}>
            <BoxSelectionItemStyled
                className={`${props.selected ? 'selected' : props.disabled ? 'disabled' : ''}`}
                onClick={() => props.onClick(props.value)}
                fullwidth={props.fullwidth}
                justifyContent={props.justifyContent}
                flexDirection={props.flexDirection}>
                {props.title && (
                    <Typography
                        variant="caption"
                        fontWeight={props.selected ? 600 : 400}
                        color={MainTemplate.palette.flavor.main}
                        lineHeight="20px"
                        textAlign={props.textAlign || 'left'}>
                        {props.title}
                    </Typography>
                )}
                {props.children && props.children}
            </BoxSelectionItemStyled>
        </ThemeProvider>
    )
})

const BoxSelectionItemStyled = styled(Box)`
    width: ${({ fullwidth }) => (fullwidth ? 'auto' : '170px')};
    border: 1px solid rgba(153, 188, 223, 0.25);
    border-radius: 6px;
    display: flex;
    justify-content: ${({ justifyContent }) => (justifyContent ? '100%' : 'center')};
    align-items: ${({ alignItems }) => (alignItems ? '100%' : 'center')};
    cursor: pointer;
    padding: ${({ fullwidth }) => (fullwidth ? '16px 18px' : '12px 5px')};

    &.selected {
        border: 2px solid #99bcdf;
    }
    &.disabled {
        background-color: rgba(153, 188, 223, 0.1);
    }
`

export default BoxSelectionItem
