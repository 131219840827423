import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeUser from 'GlobalStores/User/StoreUser'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
// import StoreReferralCart from './StoreReferralCart'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { capitalize, get } from 'lodash'
import { getOSName } from 'Helpers/Bowser'
// import { PaymentAPIs } from './Checkout/PaymentServices/PaymentAPI'
// import storeReferralCheckout from './StoreReferralCheckout'
import { storeElement } from 'GlobalStores/StoreElement'
// import storeReferral from './StoreReferral'
import { isSomething } from 'Utils/Utils'
// import StorePeriods from 'GlobalStores/StorePeriods'

class StoreFormAddress {
    typeFormAddress = 'addressHistory'
    displayMainAddress = {}
    addressHistorySelected = {}
    listAddress = []
    changeAddress = false
    loading = false
    passedToOrderSummary = false
    isInit = false

    constructor() {
        makeAutoObservable(this)
    }

    UpdateDisplayMainAddress() {
        this.displayMainAddress = this.GetAddressHistorySelected()
    }

    GetDisplayShipToName() {
        return this.displayMainAddress?.ushopShipToName
    }

    GetDisplayMainAddress() {
        return this.displayMainAddress?.displayAddress
    }

    GetDataMainAddress() {
        return this.displayMainAddress
    }

    GetListHistoryAddress() {
        return this.listAddress
    }

    SetAddressHistorySelected(obj) {
        this.addressHistorySelected = obj
    }

    GetAddressHistorySelected() {
        return this.addressHistorySelected
    }

    async GetAddressHistory() {
        if (this.loading === true) return

        runInAction(() => {
            this.loading = true
            storeElement.showCardFrontDrop = true
        })
        try {
            if (storeAuth.IsAuthorized()) {
                const response = await FeelGreatAPI.GetAddressHistory()

                if (response.data) {

                    const originalMainAddress = response.data?.mainAddress
                    const addressHistoryItems = response.data?.items || []
                    let displayMainAddress = null;

                    if (this.GetAddressHistorySelected()?.addressHref) {
                        displayMainAddress = this.GetAddressHistorySelected()

                    } else {

                        if (this.CheckEmptyAddressAndName(originalMainAddress) === false) {
                            displayMainAddress = originalMainAddress
                            addressHistoryItems?.push(response.data.mainAddress)
                        }

                    }

                    const filterItemsByCountry = addressHistoryItems?.filter(item => {

                        if (
                            this.CheckEmptyAddressAndName(item) === false &&
                            item?.shipToAddress?.country === storeCountry.Country2()
                        ) {
                            return true
                        } else {
                            return false
                        }

                    })

                    runInAction(() => {
                        if (filterItemsByCountry.length === 0) {

                            this.typeFormAddress = 'new'

                        } else {

                            if (displayMainAddress === null) {
                                displayMainAddress = filterItemsByCountry[0]
                            }
                        }
                        this.displayMainAddress = displayMainAddress
                        this.SetAddressHistorySelected(displayMainAddress)

                        this.listAddress = filterItemsByCountry
                        this.loading = false
                        storeElement.showCardFrontDrop = false
                    })
                }
            } else {
                runInAction(() => {
                    this.loading = false
                    storeElement.showCardFrontDrop = false
                    this.typeFormAddress = 'new'
                })
            }
        } catch (err) {
            runInAction(() => {
                this.loading = false
                storeElement.showCardFrontDrop = false
                this.typeFormAddress = 'new'
            })
            console.log("Error GetAddressHistory ", err)
        }
    }

    async SaveAddressHistory(form) {
        try {
            const data = {
                shipToAddress: {
                    address1: `${form.house_number} ${form.street}`,
                    address2: `${form.sub_district} ${form.district}`,
                    city: form.province,
                    country: storeCountry.Country2(),
                    state: '',
                    zip: form.zip
                },
                shipToName: {
                    fullName: form.fullname,
                    firstName: form.fullname,
                    lastName: '',
                }
            }

            await FeelGreatAPI.saveAddressHistory(data.shipToName, data.shipToAddress)
        } catch (error) {
            console.log('ERROR ADD ADDRESS HISTORY', error)
        }
    }

    FormatShipToAddress(addressData) {
        return {
            address1: `${addressData.house_number} ${addressData.street}`,
            address2: `${addressData.sub_district} ${addressData.district}`,
            city: addressData.province,
            country: storeCountry.Country2(),
            state: '',
            zip: addressData.zip
        }
    }

    CheckEmptyAddressAndName(addr) { // return true = empty

        const checkListKey = ["shipToAddress.address1", "shipToAddress.address2", "shipToAddress.city", "shipToAddress.country", "shipToAddress.zip", "ushopShipToName"]

        return checkListKey.some(key => {

            let value = get(addr, key, null)

            if (value === null) return true

            value = value.toString().replace(/\s+/g, "")

            if (value === "") {
                return true
            } else {
                return false
            }
        })
    }

}

const storeFormAddress = new StoreFormAddress()
export default storeFormAddress
