import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useCountries } from 'Services/Cores/Countries/useCountries'
import { Initialize } from 'Services/Cores/Initialize'
import StoreCountry from './GlobalStores/Cores/County/StoreCountry'
import AppRouter from './Routers/AppRouter'
import mobiscroll from '@mobiscroll/react'
import BypassRedirectPath from './Data/BypassRedirectPath.json'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import LoadingBar from 'Components/Elements/LoadingBar/LoadingBar'
import { isMaintenanceMode } from 'Helpers/GlobalHelpers'
import Maintenance from 'Components/Pages/Maintenance'
import TrackingEvents from 'Services/Tracker/TrackingEvents'
import ReactFbq from 'react-fbq'
import { get, lowerCase } from 'lodash'
import { Paths } from 'Routers/Paths'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { useState } from 'react'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeReCaptcha from 'GlobalStores/StoreReCaptcha'
import { autorun } from 'mobx'
import { useMemo } from 'react'
import { PathHelper } from 'GlobalStores/Cores/Helpers/PathHelper'
import feelGreateAPI from 'GlobalStores/Cores/County/APIs'
import SessionAuth from 'GlobalStores/User/SessionAuth'

mobiscroll.settings = {
    theme: 'ios',
    themeVariant: 'light'
}

const App = observer(() => {
    const country = useCountries()
    const qs = useMemo(() => new URLSearchParams(window.location.search), [])

    const [autoLoginLoader, setAutoLoginLoader] = useState(false)

    Initialize()

    const GetSiteVersion = useCallback(() => {
        if (country) {
            feelGreateAPI.getSiteVersion({ country: country.country.toLowerCase() }).then(res => {
                let currentVersion = localStorage.getItem('siteVersion')
                if (!currentVersion) {
                    localStorage.setItem('siteVersion', res.data.version)
                } else if (currentVersion !== res.data.version) {
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.reload()
                }
            })
        }
    }, [country])

    useEffect(() => {
        if (country) {
            GetSiteVersion()
            const currentLanguageStorage = localStorage.getItem('currentLanguage')

            let countryLowerCase = country.country.replace(/ /g, '').toLowerCase()

            if (currentLanguageStorage && currentLanguageStorage !== '' && hasLanguage(country, currentLanguageStorage)) {
                if (country.config.languageSettings.default !== currentLanguageStorage) {
                    localStorage.setItem('currentLanguage', country.config.languageSettings.default)
                    StoreTranslate.SetCurrentLanguage(country.config.languageSettings.default.replace('/ /g', ''))
                    storeReCaptcha.setCurrentLanguage(countryLowerCase, country.config.languageSettings.default.replace('/ /g', ''))
                } else {
                    StoreTranslate.SetCurrentLanguage(currentLanguageStorage)
                    storeReCaptcha.setCurrentLanguage(countryLowerCase, currentLanguageStorage)
                }
            } else {
                StoreTranslate.SetCurrentLanguage(country.config.languageSettings.default.replace('/ /g', ''))
                localStorage.setItem('currentLanguage', country.config.languageSettings.default.replace('/ /g', ''))
            }

            // set current country to local storage
            localStorage.setItem('currentCountry', countryLowerCase)
            checkToken()
            TrackingEvents.Commit(lowerCase(country.country))
            try {
                ReactFbq.initialize({
                    id: StoreCountry.FBPixelCode.all
                })
                ReactFbq.pageView()
            } catch (e) {
                console.error('FACEBOOK PIXEL ERROR', e)
            }
            if (PathHelper.Segments().length > 2 && isNaN(PathHelper.Segment(2)) && !isBypass()) {

                // Thinh told integrate with THA only [18JUN2024]
                if (PathHelper.Segment(1) === "thailand" && PathHelper.Segment(2) === "c") {
                    window.location.replace(window.location.pathname.replace('/c', ''))
                }

                if (Paths.includes(PathHelper.Segment(2))) {
                    window.location.replace(PathHelper.BuildBasename(countryLowerCase))
                }
            } else if (window.location.pathname === '/' || PathHelper.Segment(1) !== countryLowerCase) {
                window.location.replace(PathHelper.BuildBasename(countryLowerCase))
            }
        }
    }, [country, GetSiteVersion])

    /*
     * for redirecting reset password page
     */
    useEffect(() => {
        autorun(diposer => {
            if (StoreCountry.countryList) {
                if (qs.get('market') && /new-password/.test(window.location.pathname) && !!country === false) {
                    const marketCountry = StoreCountry.CountryList().find(item => item?.shorter?.toLowerCase() === lowerCase(qs.get('market')))
                    const isNewPasswordPath = PathHelper.Segment(1) === 'new-password'
                    const pathnames = window.location.pathname.split('/')
                    let newUrl = ''
                    if (marketCountry && isNewPasswordPath) {
                        if (/^\/feature/.test(window.location.pathname)) {
                            newUrl = `${window.location.protocol}//${window.location.host}/${pathnames[1]}/${pathnames[2]}/${pathnames[3]
                                }/${marketCountry.country.toLowerCase()}/new-password/${pathnames[5]}/${window.location.search}`
                        } else if (/^\/epic/.test(window.location.pathname)) {
                            newUrl = `${window.location.protocol}//${window.location.host}/${pathnames[1]}/${pathnames[2]}/${pathnames[3]}/${pathnames[4]}/${pathnames[5]
                                }/${marketCountry.country.toLowerCase()}/new-password/${pathnames[7]}/${window.location.search}`
                        } else {
                            newUrl = `${window.location.protocol}//${window.location.host}/${marketCountry.country.toLowerCase()}/new-password/${pathnames[2]
                                }/${window.location.search}`
                        }
                        diposer.dispose()
                        window.location.href = newUrl
                    }
                } else {
                    const isRedirect = checkFirstPathname()
                    if (isRedirect !== undefined) {
                        if (isRedirect) {
                            return
                        } else {
                            window.location.replace(`/australia`)
                        }
                    }

                    diposer.dispose()
                }
            }
        })

        if (localStorage.getItem('autoLogin')) {
            GetAutoLogin(localStorage.getItem('autoLogin'))
        }
    }, [])

    useEffect(() => {
        if (qs.get('autoLogin')) {
            localStorage.setItem('autoLogin', qs.get('autoLogin'))
            window.location.href = `${window.location.pathname}`
        }
    }, [qs])

    const GetAutoLogin = async token => {
        try {
            setAutoLoginLoader(true)
            await storeAuth.AutoLogin(token)
            localStorage.removeItem('autoLogin')

            setAutoLoginLoader(false)
        } catch (e) {
            setAutoLoginLoader(false)
        }
    }

    const checkFirstPathname = () => {
        if (StoreCountry.CountryList().length > 0) {
            const countryList = StoreCountry.CountryList()

            const index = countryList.findIndex(country => {
                const countryName = country.country.toLowerCase()
                return PathHelper.Segment(1) === countryName || BypassRedirectPath.includes(PathHelper.Segment(1))
            })

            return index > -1 ? true : false
        }
    }

    const checkToken = async () => {
        if (SessionAuth.GetLoginExtend('token')) {
            try {
                StoreLoader.SetApiLoaded('refreshToken', false)
                const response = await SessionAuth.CheckToken(
                    SessionAuth.GetLoginExtend('token'),
                    SessionAuth.GetLoginExtend('country'),
                    SessionAuth.GetLoginExtend('id')
                )
                if (response.data.token) {
                    SessionAuth.SetLoginExtend('token', response.data.token)
                }
                StoreLoader.SetApiLoaded('refreshToken', true)
            } catch (e) {
                SessionAuth.RemoveSession(true)
                StoreLoader.SetApiLoaded('refreshToken', true)
            }
        } else {
            StoreLoader.SetApiLoaded('refreshToken', true)
        }
    }

    return (
        <div className="App">
            {isMaintenanceMode() ? (
                <Maintenance />
            ) : (
                <>
                    <LoadingBar />
                    {StoreLoader.IsWebReady() && autoLoginLoader === false && <AppRouter />}
                </>
            )}
        </div>
    )
})

const isBypass = () => {
    const bypassPath = BypassRedirectPath

    return bypassPath.some(path => window.location.pathname.includes(path))
}

const hasLanguage = (country, currentLanguageStorage = '') => {
    const countryLanguageList = get(country, 'config.language.list', [])
    return countryLanguageList.some(lang => (lang.code || '').toLowerCase() === currentLanguageStorage.toLowerCase())
}

export default App
